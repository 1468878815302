<template>
  <div>
    <v-dialog v-model="dialogLogin" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Vendre avec cet utilisateur</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-card-title>
                <v-avatar color="primary mr-4">
                  <v-icon dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
                {{ shafteeUser.email }}
              </v-card-title>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="unConnect">Se déconnecter</v-btn>
            <v-btn
              color="success" text
              @click="displaySellPage"
              :loading="sellLoading"
            >
            VALIDER
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ title }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="loadingShaftee" style="height: 300px;">
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col
                class="text-subtitle-1 text-center"
                cols="12"
              >
                Connexion à Shaftee...
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="!loadingShaftee && !loginShaftee">
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-text-field
                prepend-icon="mdi-at"
                v-model="shafteeUser.email"
                type="text"
                label="E-mail"/>
              <v-text-field
                prepend-icon="mdi-lock"
                v-model="shafteeUser.password"
                type="password"
                label="Mot de passe"/>
            </v-row>
          </v-container>            
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="close">Annuler</v-btn>
          <v-btn
            color="success" text
            :loading="loadingShaftee"
            @click="connectToShaftee"
          >
            Se connecter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  components:{
    
  },
  props: {
  },
  data () {
    return {
      dialog: false,
      dialogLogin: false,
      dialogConnect: false,
      sellLoading: false,
      loadingShaftee: true,
      loginShaftee: false,
      shafteeUser: {
        email: null,
        password: null,
        idShaftee: null,
      },
      apiKey: null,
      title: "Vendre sur Shaftee",
      product: null,
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    connectToShaftee(){
      this.loadingShaftee = true;
      let formData = new FormData();
      formData.append('email', this.shafteeUser.email);
      formData.append('password', this.shafteeUser.password);
      formData.append('user', this.$store.state.auth.user.id);
      this.$store.dispatch('shafteeAccount/registerApiKey', formData).then(res => {
        this.apiKey = res.data.apiKey;
        this.shafteeUser.idShaftee = res.data.idShaftee
        this.dialogLogin = true;
      }).catch(() => {
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Identifiants Shaftee invalides. Merci de réessayer."
        })
      }).finally(() => {
        this.loadingShaftee = false;
        this.dialog = false;
      })
    },
    async fetchShafteeApiKey(){
      return await new Promise(resolve => this.$store.dispatch('shafteeAccount/fetchByUser', this.$store.state.auth.user.id).then((response) => {
        resolve(response);
      }))
    },
    displaySellPage(){
      this.dialogLogin = false;
      this.loadingShaftee = false;
      this.loginShaftee = true;
      this.$emit('displaySellPage', {
        product: this.product,
        apiKey: this.apiKey,
        idShaftee: this.shafteeUser.idShaftee,
      });
      this.dialog = false;
    },
    unConnect(){
      this.$store.dispatch('shafteeAccount/removeByIdShaftee', {idShaftee: this.shafteeUser.idShaftee});
      this.dialogLogin = false;
      this.open(this.product);
    },
    getUser() {
      // Get user informations of shaftee account
      this.$store.dispatch('shafteeApi/getUser', {apiKey: this.apiKey, idShaftee: this.shafteeUser.idShaftee}).then((res) => {

        this.shafteeUser.email = res.email;
        this.dialog = false;
        this.dialogLogin = true;

        // this.productShaftee.company_id = 33;
        // this.$store.dispatch('shafteeApi/getUser', {apiKey: this.apiKey}).then((res) => {

        // })
        
        // UNCOMMENT AND SUPPR UP
        // if(res.companyId){
        //   this.productShaftee.company_id = res.companyId;
        // }else{
        //   this.$store.commit('alert/add', {
        //     color: 'error',
        //     text: "Vous n'avez pas de companie associé à votre compte Shaftee. Veuillez vous rendre sur Shaftee et compléter votre profile"
        //   });
        //   this.dialog = false
        // }
      });
    },
    async open (product) {
      // Keep product for component SellProduct
      this.product = product;

      this.dialog = true
      this.loadingShaftee = true
      let response = await this.fetchShafteeApiKey()
      if(!(response.data.length > 0 && response.data[0].api_key && response.data[0].api_key != "")){
        // No connexion
        this.loginShaftee = false;
        this.loadingShaftee = false;
        this.title = "Se connecter à Shaftee"
      }else{
        // Connect to Shaftee
        this.apiKey = response.data[0].api_key;
        this.shafteeUser.idShaftee = response.data[0].idShaftee;
        this.getUser();
      }
    },
    close () {
      this.dialog = false
    },
  }
}
</script>