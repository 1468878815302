<template>
  <div v-if="product">
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Modification d'un produit</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-text-field
                prepend-icon="mdi-at"
                v-model="product.elevator.code"
                disabled
                type="text"
                label="Code de l'ascenseur"
              />
            </v-col>
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-text-field
                :prepend-icon="$vuetify.breakpoint.smAndDown ? ' ' : ''"
                v-model="product.user.email"
                type="text"
                disabled
                label="E-mail démonteur"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-combobox
                :loading="refLoading"
                prepend-icon="mdi-pound"
                item-text="num_scelle"
                placeholder
                type="text"
                label="numéro de scéllé"
                v-model="num_scelle"
                @keyup="getListNum($event.target.value)"
                @change="fillInputs($event)"
              />
              <!-- v-model="product.ref" -->
            </v-col>
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-combobox
                :loading="refLoading"
                prepend-icon="mdi-pound"
                :items="productSheetsList"
                item-text="ref"
                placeholder
                type="text"
                label="Référence"
                v-model="ref"
                @keyup="getListRef($event.target.value)"
                @change="fillInputs($event)"
              />
              <!-- v-model="product.ref" -->
            </v-col>
            <v-col cols="12" md="6" class="my-0 py-0">
              <v-text-field
                prepend-icon="mdi-cash"
                v-model="product.price"
                type="number"
                label="Prix HT"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="my-0 py-0">
              <v-text-field
                prepend-icon="mdi-label"
                v-model="product.name"
                type="text"
                label="Nom"
              />
              <v-text-field
                prepend-icon="mdi-book"
                v-model="product_category.name"
                readonly
                ref="contactTextField"
                label="Catégories"
                @click="$refs.SelectCategory.open()"
                @keydown.enter="$refs.SelectCategory.open()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <div class="mb-2">
                <div class="d-flex my-2">
                  <v-icon class="mr-2"> mdi-camera </v-icon>
                  <v-card-text class="px-0 py-0" style="font-size: 16px">
                    Images
                  </v-card-text>
                </div>
                <v-card
                  flat
                  outlined
                  class="py-4 px-4 ml-7"
                  style="width: fit-content"
                >
                  <div class="d-flex justify-center">
                    <ImageProduct
                      ref="image"
                      size="80"
                      @removeImg="removeImg(1)"
                      @addImage="addImage(1, $event)"
                    />
                    <ImageProduct
                      ref="image2"
                      @removeImg="removeImg(2)"
                      @addImage="addImage(2, $event)"
                    />
                    <ImageProduct
                      ref="image3"
                      @removeImg="removeImg(3)"
                      @addImage="addImage(3, $event)"
                    />
                  </div>
                </v-card>
              </div>
              <!-- <ImageEditProduct @removeImg="removeImg(index)" class="ml-2" v-for="(value, index) in images" :image="value" :index="index" :key="index"/> -->
            </v-col>
          </v-row>
          <v-textarea
            v-model="product.description"
            prepend-icon="mdi-book-open-variant"
            label="Description"
          />
        </v-card-text>
        <v-card-actions class="flex-wrap">
          <v-checkbox
            class="ml-3"
            v-model="product.public"
            label="Rendre le produit public"
            color="primary"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <div class="flex-wrap">
            <v-btn
              v-if="!urlProduct"
              color="primary"
              class="my-2"
              :loading="loadingUrl"
              text
              :disabled="product.id_shaftee != null"
              @click="$refs.shafteeForm.open(product)"
            >
              {{ product.id_shaftee != null ? "En vente" : "Vendre" }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              class="my-2"
              text
              @click="seeProductOnShaftee"
            >
              Voir sur Shaftee
            </v-btn>
            <v-btn color="grey" text @click="close">Annuler</v-btn>
            <v-btn
              color="success"
              text
              :loading="loading"
              :disabled="refLoading"
              @click="save"
            >
              Enregistrer
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SelectCategory ref="SelectCategory" @finished="onCategorySelected" />
    <SellProduct ref="sellForm" @finished="closeAndFetch" />
    <ShafteeConnexion
      ref="shafteeForm"
      @displaySellPage="$refs.sellForm.open($event)"
    />
  </div>
</template>

<script>
import rfdc from "rfdc";
import ImageEditProduct from "../Catalog/ImageEditProduct.vue";
import SelectCategory from "./SelectCategory.vue";
import ImageProduct from "./ImageProduct.vue";
import SellProduct from "../Catalog/SellProduct.vue";
import ShafteeConnexion from "../Catalog/ShafteeConnexion.vue";

export default {
  components: {
    ImageEditProduct,
    SelectCategory,
    ImageProduct,
    SellProduct,
    ShafteeConnexion,
  },
  props: {},
  data() {
    return {
      dialog: false,
      product: null,
      product_category: null,
      loading: false,
      loadingUrl: false,
      imagesIdToRemove: [],
      images: [],
      ref: null,
      num_scelle: null,
      urlProduct: null,
      maxImgs: 3,
      productSheetsList: [], // List of products sheets search by the ref starting name
      refLoading: false, // Loading when searching a product by its ref for autocompletion
      newProductSheetBool: true, // If true at edit validation = create a product sheet in bdd
    };
  },
  mounted() {},
  computed: {},
  methods: {
    seeProductOnShaftee() {
      window.open(this.urlProduct, "_blank");
    },
    updateImageDisplay(n, value) {
      this.$nextTick(() => {
        if (n == 0) {
          this.$refs.image.setImage(value);
        } else if (n == 1) {
          this.$refs.image2.setImage(value);
        } else if (n == 2) {
          this.$refs.image3.setImage(value);
        }
      });
    },
    removeImg(n) {
      if (
        !(
          this.product.Images[n - 1].newProduct ||
          this.product.Images[n - 1] instanceof File
        )
      ) {
        this.imagesIdToRemove.push(this.product.Images[n - 1].id);
      }
      this.product.Images[n - 1] = undefined;
      this.updateImageDisplay(n - 1, null);
    },
    addImage(n, value) {
      this.product.Images[n - 1] = value;
      this.updateImageDisplay(n - 1, URL.createObjectURL(value));
    },
    resetForm() {
      this.updateImageDisplay(0, null);
      this.updateImageDisplay(1, null);
      this.updateImageDisplay(2, null);
      this.product.Images = [];
      this.imagesIdToRemove = [];
    },
    fillInputs(value) {
      this.$nextTick(() => {
        if (value && typeof value == "string") {
          this.newProductSheetBool = true;
        } else {
          this.newProductSheetBool = false;
          if (this.product.Images) {
            this.product.Images.forEach((el, i) => {
              this.removeImg(i + 1);
            });
          }
          if (value.name) this.product.name = value.name;
          if (value.product_category)
            this.product.product_category = value.product_category;
          if (value.product_category)
            this.product_category = value.product_category;
          if (value.description) this.product.description = value.description;
          if (value.ref) this.product.ref = value.ref;
          if (value.num_scelle) this.product.num_scelle = value.num_scelle;
          console.log(value.num_scelle);
          if (value.Images) {
            value.Images.forEach((el) => {
              this.product.Images[el.order_imgs - 1] = el;
            });
            this.setImgs(this.product.Images, true, false);
          }
        }
      });
    },
    closeAndFetch() {
      this.close();
      this.$emit("finished");
    },
    onCategorySelected(value) {
      this.product_category = value;
    },
    getListRef(value) {
      if (value) {
        this.newProductSheetBool = true;
        this.product.ref = value;
        this.refLoading = true;
        let additionalParams = {
          ref: value,
        };
        this.$store
          .dispatch(`productSheets/fetchAll`, {
            itemsPerPage: 5,
            page: 1,
            additionalParams,
          })
          .then((res) => {
            this.productSheetsList = {};
            this.productSheetsList = res.member;
            this.refLoading = false;
          });
      }
    },
    getListNum(value) {
      if (value) {
        this.newProductSheetBool = true;
        this.product.num_scelle = value;
        this.refLoading = true;
        this.refLoading = false;
  
      }
    },
    open(data = null) {
      //console.log(data);
      this.product = {};
      this.newProductSheetBool = false;
      this.refLoading = false;
      this.product = rfdc()(data);
      this.ref = this.product.ref;
      this.num_scelle = this.product.num_scelle;
      this.product_category = this.product.product_category
        ? this.product.product_category
        : { name: null };
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.image.setImage(null);
        this.$refs.image2.setImage(null);
        this.$refs.image3.setImage(null);
      });
      this.product.Images = [];
      data.Images.forEach((el) => {
        this.product.Images[el.order_imgs - 1] = el;
      });
      this.setImgs(this.product.Images, false, true);

      // Get product url
      this.loadingUrl = true;
      this.$store
        .dispatch(`shafteeApi/getProduct`, {
          productId: this.product.id_shaftee,
        })
        .then((res) => {
          this.urlProduct = res.url;
        })
        .catch((err) => {
          console.log("error =>");
          console.log(err);
        })
        .finally(() => {
          this.loadingUrl = false;
        });
    },
    setImgs(data, newProduct, newProductSheet) {
      if (data) {
        data.forEach((el) => {
          el.newProduct = newProduct;
          el.newProductSheet = newProductSheet;
        });
        data.forEach((element, i) => {
          this.updateImageDisplay(i, element.url);
        });
      }
    },
    close() {
      this.ref = null;
      this.num_scelle = null;
      this.newProductSheetBool = false;
      this.dialog = false;
      this.$refs.image.setImage(null);
      this.$refs.image2.setImage(null);
      this.$refs.image3.setImage(null);
      this.resetForm();
    },
    async createProductSheet() {
      let formData = new FormData();
      console.log("product ref : ", this.product.ref);
      formData.append("ref", this.product.ref ? this.product.ref : "");
      formData.append("num_scelle", this.product.num_scelle ? this.product.num_scelle : "");
      formData.append("name", this.product.name ? this.product.name : "");
      formData.append(
        "product_category",
        this.product_category && this.product_category.id_shaftee
          ? this.product_category.id_shaftee
          : null
      );
      formData.append(
        "description",
        this.product.description ? this.product.description : ""
      );

      // Register images
      if (this.product.Images) {
        this.product.Images = await Promise.all(
          this.product.Images.map(async (el, i, arr) => {
            if (el) {
              if (!(el instanceof File)) {
                if (el.newProductSheet) {
                  el = await this.createImg(el.url);
                }
              }
              if (el instanceof File) {
                if (i == 0) {
                  formData.append("images", el);
                } else if (i == 1) {
                  formData.append("images2", el);
                } else if (i == 2) {
                  formData.append("images3", el);
                }
              }
              return el;
            }
          })
        );
      }

      let params = {};
      params.formData = formData;

      this.$store.dispatch(`productSheets/createProductSheet`, params);
    },
    async createImg(url) {
      let result = await fetch(url);
      let blob = await result.blob();
      let file = new File([blob], url, { type: blob.type });
      return file;
    },
    async updateProduct() {
      let formData = new FormData();
      formData.append("id", this.product.id);
      formData.append("ref", this.product.ref ? this.product.ref : "");
      formData.append("num_scelle", this.product.num_scelle ? this.product.num_scelle : "");
      formData.append("name", this.product.name ? this.product.name : "");
      if (this.product.price) {
        formData.append("price", this.product.price);
      }
      formData.append(
        "product_category",
        this.product_category ? this.product_category.id_shaftee : null
      );
      formData.append(
        "description",
        this.product.description ? this.product.description : ""
      );
      formData.append("new", false);
      formData.append(
        "public",
        this.product.public ? this.product.public : false
      );
      if (this.imagesIdToRemove.length > 0) {
        formData.append(
          "imagesToRemove",
          JSON.stringify(this.imagesIdToRemove)
        );
      }

      // Register images
      if (this.product.Images) {
        this.product.Images = await Promise.all(
          this.product.Images.map(async (el, i, arr) => {
            if (el) {
              if (!(el instanceof File)) {
                if (el.newProduct) {
                  el = await this.createImg(el.url);
                }
              }
              if (el instanceof File) {
                if (i == 0) {
                  formData.append("images", el);
                } else if (i == 1) {
                  formData.append("images2", el);
                } else if (i == 2) {
                  formData.append("images3", el);
                }
              }
              return el;
            }
          })
        );
      }
      let params = {};
      params.formData = formData;
      console.log(params);
      params.id = this.product.id;
      await this.$store
        .dispatch(`products/updateProduct`, params)
        .then((res) => {
          this.$store.commit("alert/add", {
            color: "success",
            text: "Le produit a bien été modifié !",
          });
        })
        .catch(() => {
          this.$store.commit("alert/add", {
            color: "error",
            text: "Les modifications sur le produit n'ont pas pu être enregistrées",
          });
        });
    },
    async save() {
      this.loading = true;
      if (this.newProductSheetBool || this.ref) {
        // Create new product_sheet
        await this.createProductSheet();
      }
      await this.updateProduct();
      this.loading = false;
      this.close();
      this.$emit("finished");
    },
  },
};
</script>
