<template>
  <v-container>
    <ListCatalog v-if="this.$route.name == 'Catalog/Table'" />
    <ViewCatalog v-else />
  </v-container>
</template>

<script>
import ViewCatalog from "@/components/Catalog/ViewCatalog.vue";
import ListCatalog from "@/components/Catalog/ListCatalog.vue";

export default {
  components: { ViewCatalog, ListCatalog },
  mounted () {
    console.log(this.$route.name);
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>
