<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-book-search</v-icon>
        Recherche d'une catégorie
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="5">
            <v-select
              label="Par"
              disabled
              :value="{label: 'Nom'}"
              item-text="label"
              :items="[{label: 'Nom'}]"
            />
          </v-col>
          <v-col cols="12" sm="7">
            <v-text-field
              v-model="query"
              label="Recherche"
            />
          </v-col>
        </v-row>
        <v-progress-linear
          indeterminate
          class="mt-2"
          :style="{ opacity: loading ? 1 : 0 }"
        />
        <v-layout
          v-if="!loading && query.length === 0 && !enableResults && categories.length === 0"
          class="mt-8" justify-center
        >
          <div>Aucune catégorie à suggérer</div>
        </v-layout>
        <v-layout
          v-if="!loading && enableResults && noResults"
          class="mt-8" justify-center
        >
          <div>Aucune catégorie suite à la recherche </div>
        </v-layout>
        <v-list v-if="(enableResults && categories.length > 0) || (!enableResults && categories.length > 0)">
          <v-subheader v-if="!enableResults">
            <span>Suggestion de catégories</span>
          </v-subheader>
          <v-divider />
          <template
            v-for="category in categories">
            <v-list-item
              :key="category.id"
              ripple
              @click="selectCategory(category)"
            >
              <v-list-item-avatar>
                <v-icon v-if="selectedCategory && selectedCategory.id === category.id">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else>mdi-book</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <div>
                  {{ category.nameLevel1 + " / " + category.nameLevel2 }}
                </div>
                <v-list-item-title>
                   {{ category.nameLevel3 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'divider__' + category.id" />
          </template>
        </v-list>
        <div v-else class="pb-4 pt-4">
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close">
          Annuler
        </v-btn>
        <v-btn
          text
          color="secondary"
          :disabled="!selectedCategory"
          @click="submit"
        >
          Sélectionner
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    closeOnSubmit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dialog: false,
    field: 'email',
    query: '',
    categories: [],
    loading: false,
    selectedCategory: null,
    noResults: false,
  }),
  watch: {
    query () {
      this.noResults = false
      debounce(this.fetchCategories, 500)()
    },
    field () {
      this.fetchCategories()
    }
  },
  computed: {
    enableResults () {
      return !(this.field === '' || this.query === '')
    },
  },
  methods: {
    open() {
      this.selectedCategory = null;
      this.categories = []
      this.dialog = true
      this.noResults = false
      this.fetchCategories()
    },
    close() {
      this.dialog = false
    },
    reset() {
      this.selectedCategory = null
      this.query = ''
    },
    // Get list of categories from db
    fetchCategories(){
      this.loading = true;
      let additionalParams = {
        itemsPerPage: 5,
      }
      if(this.query && this.query != ''){
        additionalParams.name_level1 = this.query;
        additionalParams.name_level2 = this.query;
        additionalParams.name_level3 = this.query;
      }
      this.$store.dispatch('productCategories/fetchAll', {
        additionalParams
      }).then(res => {
        this.categories = res.member;
        this.loading = false;
        if(this.categories.length === 0){
          this.noResults = true;
        }
      })
    },
    selectCategory(category) {
      if (category === this.selectedCategory) {
        this.selectedCategory = null
      } else {
        this.selectedCategory = category
      }
    },
    submit () {
      this.$emit('finished', this.selectedCategory)
      if (this.closeOnSubmit) {
        this.close()
      }
    }
  }
}
</script>
