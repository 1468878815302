<template>
  <v-container>
    <v-card>
      <ListUsers
        v-if="enable"
        :company="company"
        :isLinkedToCompany="isLinkedToCompany"
      />
    </v-card>
  </v-container>
</template>

<script>
import ListUsers from '../../components/Users/ListUsers.vue'

export default {
  components: { ListUsers },
  mounted () {
    this.refresh()
  },
  data: () => ({
    enable: false,
    company: null,
    isLinkedToCompany: false,
  }),
  watch: {
    '$store.state.user' () {
      this.refresh()
    }
  },
  methods: {
    refresh () {
      if (this.$store.state.auth.user.isAdmin) {
        this.isLinkedToCompany = false
        this.company = null
      } else {
        this.$store.commit('setTitle', 'Utilisateurs de votre entreprise')
        this.isLinkedToCompany = true
        if (!this.$store.state.auth.user.company) {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Votre compte n'est pas encore lié à une entreprise"
          })
          return
        }
        this.company = this.$store.state.auth.user.company
      }
      this.enable = true
    }
  }
}
</script>
