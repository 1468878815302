<template>
  <div>
    <v-avatar
      class="overflow-visible"
      rounded
      color="grey"
      size="50"
    >
      <img v-if="image" :src="image.type == 'bdd' || image.type == 'bdd-product-sheet' ? image.url : image.url" alt="">
      <v-btn
        v-if="image"
        class="mx-2 mt-3 ml-4"
        height=24
        width=24
        left
        top
        absolute
        fab
        dark
        small
        color="error"
        @click="$emit('removeImg', index)"
      >
        <v-icon size=16 dark>
          mdi-close-thick
        </v-icon>
      </v-btn>
    </v-avatar>
  </div>
</template>

<script>

export default {
  components:{
    
  },
  props: {
    image: {
      type: String | File | Object,
      default: null,
    },
    index:{
      type: Number,
      default: null,
    }
  },
  data () {
    return {

    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {

  },
}
</script>