<template>
    <v-container>
      <v-card>
        <dashbordElevator
        />
      </v-card>
    </v-container>
  </template>
  
  <script>
  import dashbordElevator from '../../components/Elevators/dashbordElevator.vue'
  
  export default {
    components: { dashbordElevator },
    mounted () {
      //this.refresh()
    },
    data: () => ({
    }),
  }
  </script>