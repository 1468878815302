<template>
  <div>
    <!-- Filters -->
    <v-card :color="this.$store.state.theme" :loading="isUpdating" class="mb-3">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="6" md="2">
              <v-text-field
                label="Prix HT min"
                outlined
                dense
                v-model="filters.price.minPrice"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                dense
                label="Prix HT max"
                outlined
                v-model="filters.price.maxPrice"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                v-model="filters.name"
                :disabled="isUpdating"
                color="primary"
                label="Nom"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="filters.categories.name"
                readonly
                outlined
                dense
                ref="contactTextField"
                label="Catégories"
                @click="$refs.SelectCategory.open()"
                @keydown.enter="$refs.SelectCategory.open()"
              >
              </v-text-field>
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item disabled>
                  <v-list-item-avatar color="primary">
                    <v-icon> mdi-arrow-up-thick </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title> Fin de la liste. </v-list-item-title>
                    <v-list-item-subtitle>
                      Remonte pour sélectionner les catégories qui t'interessent
                      !
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- </v-select> -->
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="elevators"
                label="Ascenseur"
                v-model="filters.elevator"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="sites"
                label="Chantier"
                v-model="filters.site"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions class="flex-wrap">
        <v-checkbox
          class="ml-3"
          v-model="filters.public"
          @change="search"
          label="Voir tous les produits"
          color="primary"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn
          :loading="isUpdating"
          color="grey"
          depressed
          @click="resetSearch"
        >
          <v-icon left> mdi-close-circle-outline </v-icon>
          Rétablir
        </v-btn>
        <v-btn :loading="isUpdating" color="primary" depressed @click="search">
          <v-icon left> mdi-update </v-icon>
          Rechercher
        </v-btn>
        <v-card flat class="ml-4">
          <v-btn
            tile
            icon
            large
            dense
            class="mr-2"
            outlined
            @click="$router.push('/catalog/cards')"
          >
            <v-icon> mdi-dots-grid </v-icon>
          </v-btn>
          <v-btn large icon tile style="background-color: #058d36">
            <v-icon> mdi-format-list-text </v-icon>
          </v-btn>
        </v-card>
      </v-card-actions>
      <template v-slot:progress>
        <v-progress-linear
          absolute
          color="primary"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
    </v-card>
    <!-- Content -->
    <v-card>
      <v-data-table
        :headers="headers"
        dense
        :items="products"
        :loading="tableLoading"
        :options.sync="options"
        :server-items-length="totalCount"
        @dblclick:row="onClickedRow"
      >
        <template v-slot:top>
          <v-toolbar flat color="transparent">
            <v-layout style="max-width: 300px">
              <v-text-field
                ref="searchField"
                v-model="query"
                hide-details
                label="Recherche"
                clearable
                clear-icon="mdi-close"
                @click:clear="
                  query = '';
                  fetchData();
                "
              />
            </v-layout>
            <v-spacer />

            <v-btn
              outlined
              small
              icon
              dark
              color="primary"
              class="mb-2 mr-2"
              @click="fetchData()"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span v-if="item.price"> {{ item.price }} € </span>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAtObject | formatDate }}
        </template>
        <template v-slot:[`item.sellDate`]="{ item }">
          {{ item.sellDate | formatDate }}
        </template>
        <template v-slot:item.new="{ item }">
          <v-chip v-if="item.new" dark class="ma-2" color="red">
            Nouveau
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip v-if="$store.state.auth.user.isAdmin" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="!item.editable" icon small color="primary">
                <v-icon
                  small
                  @click="$refs.shafteeForm.open(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-gavel
                </v-icon>
              </v-btn>
            </template>
            <span>Vendre</span>
          </v-tooltip>
          <v-tooltip v-if="$store.state.auth.user.isAdmin" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="!item.editable" icon small color="info">
                <v-icon
                  small
                  @click="$refs.editForm.open(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <v-tooltip v-if="$store.state.auth.user.isAdmin" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="!item.editable" icon small color="error">
                <v-icon
                  small
                  @click="$refs.removeForm.open(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <RemoveItem
      resource="products"
      title="ce produit"
      ref="removeForm"
      @finished="search"
    />
    <EditProduct ref="editForm" @finished="search" />
    <SellProduct ref="sellForm" @finished="search" />
    <ShafteeConnexion
      ref="shafteeForm"
      @displaySellPage="$refs.sellForm.open($event)"
    />
    <SelectCategory ref="SelectCategory" @finished="onCategorySelected" />
  </div>
</template>

<script>
import RemoveItem from "../RemoveItem.vue";
import EditProduct from "../Catalog/EditProduct.vue";
import SellProduct from "../Catalog/SellProduct.vue";
import ShafteeConnexion from "../Catalog/ShafteeConnexion.vue";
import SelectCategory from "./SelectCategory.vue";

import products from "./../../utils/products";
import { debounce } from "lodash";

export default {
  components: {
    RemoveItem,
    EditProduct,
    SellProduct,
    ShafteeConnexion,
    SelectCategory,
  },
  props: {},
  data() {
    return {
      headers: [
        ...(this.$store.state.debugMode
          ? [
              {
                text: "Id",
                value: "id",
              },
            ]
          : []),
        {
          text: "",
          value: "new",
        },
        {
          text: "Nom du produit",
          value: "name",
        },
        {
          text: "Référence",
          value: "ref",
        },
        {
          text: "Numéro d'ascenceur",
          value: "elevator.code",
        },
        {
          text: "Catégorie produit",
          value: "product_category.name",
        },
        {
          text: "Prix HT",
          value: "price",
        },
        {
          text: "Créé le",
          value: "createdAt",
        },
        {
          text: "Mis en vente le",
          value: "sellDate",
        },
        {
          text: 'numéro de scellé',
          value: 'num_scelle'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      options: {},
      query: "",
      readOnly: false,
      isUpdating: false, // Hide content & display loaders on true
      filters: {
        price: {
          minPrice: null,
          maxPrice: null,
        },
        name: null,
        categories: {
          name: null,
        },
        elevator: null,
        site: null,
      },
      products: [],
      tableLoading: true,
      totalCount: 0,
      categories: [],
      sites: [],
      elevators: [],
    };
  },
  mounted() {
    // If not admin can't update/remove/sell products
    if (!this.$store.state.auth.user.isAdmin) {
      this.readOnly = true;
    }
    this.isUpdating = true;

    this.fetchSites();
  },
  watch: {
    query: debounce(function (_) {
      this.fetchData();
    }, 500),
    options: {
      handler() {
        if (this.isLinkedToCompany && !this.company.id) {
          return;
        }
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    resetSearch() {
      this.filters = {
        price: {
          minPrice: null,
          maxPrice: null,
        },
        name: null,
        categories: {
          name: null,
        },
        elevator: null,
        site: null,
      };
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
    onClickedRow(_, { item }) {
      if (!this.$store.state.auth.user.isAdmin || !item.editable) {
        return;
      }
      this.$refs.editForm.open(item);
    },
    onCategorySelected(value) {
      this.filters.categories = value;
    },
    fetchData() {
      this.isUpdating = true;
      let additionalParams = {
        "elevator.code": this.filters.elevator ? this.filters.elevator : null,
        "elevator.site.name": this.filters.site ? this.filters.site : null,
        name: this.filters.name ? this.filters.name : null,
        "product_category.id_shaftee": this.filters.categories.id_shaftee
          ? this.filters.categories.id_shaftee
          : null,
        "price[gt]": this.filters.price.minPrice
          ? this.filters.price.minPrice
          : null,
        "price[lt]": this.filters.price.maxPrice
          ? this.filters.price.maxPrice
          : null,
      };
      if (this.query && this.query.length > 0) {
        ["searchList"].forEach((key) => {
          additionalParams[key] = this.query;
        });
      }
      this.tableLoading = true;

      if (!this.$store.state.auth.user.isAdmin && !this.filters.public) {
        additionalParams["searchOnlyMy"] =
          this.$store.state.auth.user.company.id;
      } else if (!this.$store.state.auth.user.isAdmin && this.filters.public) {
        additionalParams["searchAllProducts"] =
          this.$store.state.auth.user.company.id;
      }
      this.$store
        .dispatch("products/fetchAll", { ...this.options, additionalParams })
        .then((res) => {
          this.products = res.member;
          console.log(this.products);
          this.totalCount = res.totalItems;
        })
        .finally(() => {
          this.tableLoading = false;
          this.isUpdating = false;
        });
      if (this.$store.state.auth.user.isAdmin) {
        products.countNewProducts(this.$store);
      }
    },
    fetchSites() {
      let additionalParams = {};
      if (this.$store.state.auth.user.company) {
        additionalParams.finalClient = this.$store.state.auth.user.company.id;
        additionalParams.ascensorist = this.$store.state.auth.user.company.id;
        additionalParams.amo = this.$store.state.auth.user.company.id;
      }
      this.$store
        .dispatch("sites/fetchAll", { additionalParams })
        .then((res) => {
          this.sites = res.member.map((val) => val.name);
          let arraysOfElevators = res.member
            .map((val) => val.elevators.map((r) => r.code))
            .filter((val) => val.length > 0);
          arraysOfElevators.forEach((val) => {
            this.elevators.push(...val);
          });
        });
    },
  },
};
</script>
