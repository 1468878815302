<template>
    <div>
      <v-data-table
        :headers="headers"
        :items="elevators"
        :options.sync="options"
      >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-spacer />
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-2"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.site`]="{ item }">
        {{ item.site }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate }}
      </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
              icon small color="info"
            >
            </v-btn>
          
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
            >
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
      </template>
      </v-data-table>
      <EditElevator
      ref="editForm"
      :default-site="site"
      @finished="fetchData"
      />
      <span></span>
    </div>
  </template>
  
  <script>
  import EditElevator from "./EditElevator.vue"
  export default {
    components: {
        EditElevator
    },
    props: {
    },
    data () {
      return {
        headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: "Numéro d'ascenseurs",
          value: 'code'
        },
        {
          text: 'Constructeur',
          value: 'constructor.name'
        },
        ...(this.site ? [] : [{
          text: 'Site',
          value: 'site.name'
        }]),
        {
          text: 'Créé le',
          value: 'createdAt'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
        elevators: [],
        options: {

        },

      }
    },
    watch: {
      options: {
        handler () {
          this.fetchData()
        }
      }
    },
    mounted () {
    },
    methods: {
        fetchData () {
            let action = null
            action = this.$store.dispatch(
            'elevators/fetchAll',
            this.options
            )
            action.then(res => {
                this.elevators= res.member
                console.log(this.elevators);
            }).catch((err) => {
                console.error(err)
            })
        },
    }
  }
  </script>
  