<template>
  <div v-if="productShaftee">
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Vendre ce produit</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                prepend-icon="mdi-pound"
                v-model="productShaftee.ref"
                disabled
                type="text"
                label="Référence"/>
              <v-text-field
                prepend-icon="mdi-at"
                v-model="productShaftee.product_code"
                type="text"
                label="Code EAN"/>
              <v-text-field
                prepend-icon="mdi-label"
                v-model="productShaftee.name"
                type="text"
                label="Nom"/>
              <v-text-field
                prepend-icon="mdi-book"
                v-model="productShaftee_category.name"
                readonly
                ref="contactTextField"
                label="Catégories"
                @click="$refs.SelectCategory.open()"
                @keydown.enter="$refs.SelectCategory.open()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="my-auto">
              <v-carousel v-if="productShaftee.Images && productShaftee.Images.length > 0" height="200" width="50" class="mx-auto" style="width: 220px;">
                <v-carousel-item
                  
                  v-for="(image, i) in productShaftee.Images"
                  :key="image.name"
                >
                  <v-img
                    height="100%"
                    aspect-ratio="1"
                    :src="image.url"
                  ></v-img>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <div class="text-h2">
                      Slide {{ i + 1 }}
                    </div>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
              <div align="center" v-else>
                Pas de photos enregistrés
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                prepend-icon="mdi-cash-multiple"
                v-model="productShaftee.price"
                type="number"
                label="Prix HT"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                prepend-icon="mdi-progress-wrench"
                v-model="productShaftee.condition"
                :items="conditions"
                label="État"
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col>
              <v-textarea
                v-model="productShaftee.description"
                prepend-icon="mdi-book-open-variant"
                label="Description"
              />
            </v-col>
          </v-row>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="close">Annuler</v-btn>
          <v-btn
            color="success" text
            @click="uploadOnShaftee"
            :loading="sellLoading"
            :disabled="allFieldsFull()"
          >
            MISE EN LIGNE
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SelectCategory
      ref="SelectCategory"
      @finished="onCategorySelected"
    />
  </div>
</template>

<script>
import rfdc from 'rfdc';
import SelectCategory from "./SelectCategory.vue";

export default {
  components:{
    SelectCategory,
  },
  props: {

  },
  data () {
    return {
      dialog: false,
      email: null,
      productShaftee: {
        name: null,
      },
      productShaftee_category: {
        name: null,
      },
      idShaftee: null,
      categoriesShaftee: null,
      conditions: [
        {
          name: "Neuf",
          id: "N"
        },
        {
          name: "Occasion",
          id: "U"
        },
      ],
      apiKey: null,
      sellLoading: false, // Display loader when api call to upload product on Shaftee on going
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    // async setDateSell(){
    //   let params = {};
    //   let formData = new FormData;
    //   formData.append('sellOn', true);
    //   params.formData = formData;
    //   params.id = this.productShaftee.id;
    //   return await this.$store.dispatch(`products/updateProduct`, params);
    // },
    allFieldsFull(){
      if(this.productShaftee.product_code && this.productShaftee.price && this.productShaftee.name && this.productShaftee.condition && this.productShaftee.description && this.productShaftee_category.id_shaftee){
        return false;
      }
      return true;
    },
    uploadOnShaftee(){
      this.sellLoading = true;
      let product = {
        product_code: this.productShaftee.product_code,
        supplier_ref: this.productShaftee.ref,
        product: this.productShaftee.name,
        condition: this.productShaftee.condition,
        short_description: this.productShaftee.description,
        full_description: this.productShaftee.description,
        company_id: this.productShaftee.company_id,
        main_category: this.productShaftee_category.id_shaftee,
        main_pair: {
          detailed: {
            image_path: this.productShaftee.Images[0] ? this.productShaftee.Images[0].url : null
          }
        },
        image_pairs:
        [
          {
            detailed: {
              image_path: this.productShaftee.Images[1] ? this.productShaftee.Images[1].url : null
            }
          },
          {
            detailed: {
              image_path: this.productShaftee.Images[2] ? this.productShaftee.Images[2].url : null
            }
          }
        ],
        inventory: [
          {
            price: this.productShaftee.price,
            amount: 1,
          }
        ],
        tax_ids: [5] //20%
      }
      this.$store.dispatch('shafteeApi/uploadProduct', {apiKey: this.apiKey, product: product}).then((res) => {
        
        // Register id_shaftee in bdd
        this.$store.dispatch('products/update', {
          id: this.productShaftee.id,
          idShaftee: res.product_id.toString(),
          sellDate: new Date(),
        }).then(() =>{
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le produit a bien été mis en vente sur Shaftee !"
          })
          this.$emit('finished');
          this.close();
        })
      }).catch(() => {
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Impossible de mettre le produit en vente sur Shaftee"
        })
      }).finally(() => {
        this.sellLoading = false;
      })
    },
    onCategorySelected(value){
      this.productShaftee_category = value;
    },
    open (datas = null) {
      this.idShaftee = datas.idShaftee;
      this.apiKey = datas.apiKey;
      this.productShaftee = rfdc()(datas.product);
      this.productShaftee.Images = this.productShaftee.Images.filter(n => n)
      this.productShaftee_category =  this.productShaftee.product_category ? this.productShaftee.product_category : {name: null};
      this.dialog = true
      // Get user informations of shaftee account
      this.$store.dispatch('shafteeApi/getUser', {apiKey: this.apiKey, idShaftee: this.idShaftee}).then((res) => {
        
        // TO UNCOMMENT
        res.companyId = 33;
        
        if(res.companyId){
          this.productShaftee.company_id = res.companyId;
        }else{
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Vous n'avez pas de companie associé à votre compte Shaftee. Veuillez vous rendre sur Shaftee et compléter votre profile"
          });
          this.dialog = false
        }
      });

    },
    close () {
      this.dialog = false
    },
  }
}
</script>