<template>
  <div>
    <!-- Filters -->
    <v-card :color="this.$store.state.theme" :loading="isUpdating">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="6" md="2">
              <v-text-field
                label="Prix HT min"
                outlined
                dense
                v-model="filters.price.minPrice"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                dense
                label="Prix HT max"
                outlined
                v-model="filters.price.maxPrice"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                v-model="filters.name"
                :disabled="isUpdating"
                color="primary"
                label="Nom"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="filters.categories.name"
                readonly
                outlined
                dense
                ref="contactTextField"
                label="Catégories"
                @click="$refs.SelectCategory.open()"
                @keydown.enter="$refs.SelectCategory.open()"
              >
              </v-text-field>
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item disabled>
                  <v-list-item-avatar color="primary">
                    <v-icon> mdi-arrow-up-thick </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title> Fin de la liste. </v-list-item-title>
                    <v-list-item-subtitle>
                      Remonte pour sélectionner les catégories qui t'interessent
                      !
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- </v-select> -->
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="elevators"
                label="Ascenseur"
                v-model="filters.elevator"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="sites"
                label="Chantier"
                v-model="filters.site"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions class="flex-wrap">
        <v-checkbox
          class="ml-3"
          v-model="filters.public"
          @change="search"
          label="Voir tous les produits"
          color="primary"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn
          :loading="isUpdating"
          color="grey"
          depressed
          @click="resetSearch"
        >
          <v-icon left> mdi-close-circle-outline </v-icon>
          Rétablir
        </v-btn>
        <v-btn :loading="isUpdating" color="primary" depressed @click="search">
          <v-icon left> mdi-update </v-icon>
          Rechercher
        </v-btn>
        <v-card flat class="ml-4">
          <v-btn
            tile
            icon
            large
            dense
            class="mr-2"
            style="background-color: #058d36"
          >
            <v-icon> mdi-dots-grid </v-icon>
          </v-btn>
          <v-btn
            large
            icon
            tile
            outlined
            @click="$router.push('/catalog/table')"
          >
            <v-icon> mdi-format-list-text </v-icon>
          </v-btn>
        </v-card>
      </v-card-actions>
      <template v-slot:progress>
        <v-progress-linear
          absolute
          color="primary"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
    </v-card>

    <div v-if="products.length > 0">
      <!-- Content -->
      <v-item-group>
        <v-container>
          <v-row>
            <v-col
              v-for="product in products"
              :key="product.id"
              cols="12"
              md="4"
            >
              <v-item>
                <v-card class="mx-auto my-12" max-width="374">
                  <template slot="progress">
                    <v-progress-linear
                      color="primary"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>
                  <v-chip
                    dark
                    v-if="product.new != 0"
                    style="position: absolute; z-index: 5; right: 0px; top: 0px"
                    class="ma-2"
                    color="red"
                  >
                    Nouveau
                  </v-chip>
                  <v-carousel
                    v-if="product.Images.length > 0"
                    height="250"
                    aspect-ratio="1"
                  >
                    <v-carousel-item
                      v-for="(image, i) in product.Images"
                      :key="i"
                    >
                      <v-img
                        height="100%"
                        aspect-ratio="1"
                        :src="image.url"
                      ></v-img>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="text-h2">Slide {{ i + 1 }}</div>
                      </v-row>
                    </v-carousel-item>
                  </v-carousel>
                  <v-img
                    v-else
                    height="250"
                    aspect-ratio="1"
                    :src="
                      product.Images.length > 0
                        ? product.Images[0].url
                        : readOnly
                        ? require('../../assets/images/elevator-product-no-text.jpg')
                        : require('../../assets/images/elevator-product.jpg')
                    "
                  ></v-img>

                  <v-card-title>{{
                    product.name ? product.name : "Nom à compléter"
                  }}</v-card-title>

                  <v-card-text>
                    <v-row align="center" class="mx-0">
                      <div class="grey--text ms-0">
                        Ascenseur : {{ product.elevator.code }}
                      </div>
                      <br />
                    </v-row>

                    <div class="my-4 text-subtitle-1">
                      <p v-if="product.price">
                        {{ product.price }} <span class="primary--text">€</span>
                      </p>
                      <p v-else>Aucun prix</p>
                    </div>

                    <div>
                      {{
                        product.description
                          ? product.description
                          : "Ce produit n'a pas encore de description."
                      }}
                    </div>
                  </v-card-text>

                  <div v-if="!readOnly">
                    <v-divider class="mx-4"></v-divider>

                    <v-card-actions>
                      <v-btn
                        :disabled="!product.editable"
                        class="info--text"
                        @click="$refs.editForm.open(product)"
                        text
                      >
                        Modifier
                      </v-btn>
                      <v-btn
                        :disabled="!product.editable"
                        class="error--text"
                        text
                        @click="$refs.removeForm.open(product)"
                      >
                        SUPPR
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        :disabled="
                          product.id_shaftee != null || !product.editable
                        "
                        @click="$refs.shafteeForm.open(product)"
                      >
                        {{ product.id_shaftee != null ? "En vente" : "Vendre" }}
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>

      <!-- Pagination -->
      <div v-if="!isUpdating && products.length > 0" class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(nbProducts / nbPerPage)"
                  class="my-4"
                  @input="changePage()"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="text-center grey--text mt-4 text-body-2" v-else>
      <p v-if="isUpdating">Chargement de l'élement...</p>
      <p v-else>Aucune donnée disponible</p>
    </div>
    <RemoveItem
      resource="products"
      title="ce produit"
      ref="removeForm"
      @finished="search"
    />
    <EditProduct ref="editForm" @finished="search" />
    <SellProduct ref="sellForm" @finished="search" />
    <ShafteeConnexion
      ref="shafteeForm"
      @displaySellPage="$refs.sellForm.open($event)"
    />
    <SelectCategory ref="SelectCategory" @finished="onCategorySelected" />
  </div>
</template>

<script>
import RemoveItem from "../RemoveItem.vue";
import EditProduct from "../Catalog/EditProduct.vue";
import SellProduct from "../Catalog/SellProduct.vue";
import ShafteeConnexion from "../Catalog/ShafteeConnexion.vue";
import SelectCategory from "./SelectCategory.vue";

import products from "./../../utils/products";

export default {
  components: {
    RemoveItem,
    EditProduct,
    SellProduct,
    ShafteeConnexion,
    SelectCategory,
  },
  props: {},
  data() {
    return {
      filters: {
        price: {
          minPrice: null,
          maxPrice: null,
        },
        name: null,
        categories: {
          name: null,
        },
        elevator: null,
        site: null,
      },
      readOnly: false,
      isUpdating: false, // Hide content & display loaders on true
      products: [],
      nbProducts: 0,
      page: 1,
      nbPerPage: 9,
      categories: [],
      sites: [],
      elevators: [],
    };
  },
  async mounted() {
    // If not admin can't update/remove/sell products
    if (!this.$store.state.auth.user.isAdmin) {
      this.readOnly = true;
    }
    this.fetchData();

    this.fetchSites();
  },
  watch: {
    // isUpdating (val) {
    //   if (val) {
    //     this.search();
    //   }
    // },
  },
  computed: {},
  methods: {
    resetSearch() {
      this.filters = {
        price: {
          minPrice: null,
          maxPrice: null,
        },
        name: null,
        categories: {
          name: null,
        },
        elevator: null,
        site: null,
      };
      this.fetchData();
    },
    changePage() {
      this.scrollToTop();
      this.fetchData();
    },
    search() {
      this.isUpdating = true;
      this.fetchData();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onCategorySelected(value) {
      this.filters.categories = value;
    },
    fetchData() {
      this.isUpdating = true;
      let additionalParams = {
        "elevator.code": this.filters.elevator ? this.filters.elevator : null,
        "elevator.site.name": this.filters.site ? this.filters.site : null,
        name: this.filters.name ? this.filters.name : null,
        "product_category.id_shaftee": this.filters.categories.id_shaftee
          ? this.filters.categories.id_shaftee
          : null,
        "price[gt]": this.filters.price.minPrice
          ? this.filters.price.minPrice
          : null,
        "price[lt]": this.filters.price.maxPrice
          ? this.filters.price.maxPrice
          : null,
      };
      if (!this.$store.state.auth.user.isAdmin && !this.filters.public) {
        additionalParams["searchOnlyMy"] =
          this.$store.state.auth.user.company.id;
      } else if (!this.$store.state.auth.user.isAdmin && this.filters.public) {
        additionalParams["searchAllProducts"] =
          this.$store.state.auth.user.company.id;
      }
      this.$store
        .dispatch("products/fetchAll", {
          itemsPerPage: this.nbPerPage,
          sortBy: ["updatedAt"],
          sortDesc: [false],
          page: this.page,
          additionalParams,
        })
        .then((res) => {
          console.log(res.member);
          this.products = res.member;
          this.nbProducts = res.totalItems;
          this.isUpdating = false;
        });
      if (this.$store.state.auth.user.isAdmin) {
        products.countNewProducts(this.$store);
      }
    },
    // Get sites list from db by the connected user
    fetchSites() {
      let additionalParams = {};
      if (this.$store.state.auth.user.company) {
        additionalParams.finalClient = this.$store.state.auth.user.company.id;
        additionalParams.ascensorist = this.$store.state.auth.user.company.id;
        additionalParams.amo = this.$store.state.auth.user.company.id;
      }
      this.$store
        .dispatch("sites/fetchAll", { additionalParams })
        .then((res) => {
          this.sites = res.member.map((val) => val.name);
          let arraysOfElevators = res.member
            .map((val) => val.elevators.map((r) => r.code))
            .filter((val) => val.length > 0);
          arraysOfElevators.forEach((val) => {
            this.elevators.push(...val);
          });
        });
    },
  },
};
</script>
